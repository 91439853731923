.meat-card-container {
  width: 16rem;
  border-radius: 1rem;
  box-shadow: 0px 10px 8px #999;
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  background-color: white;
  height: 28rem;
}

.meat-card-img {
  width: 100%;
  height: fit-content;
  border-radius: 1rem 1rem 0 0;
}

.meat-card-title {
  margin: 1rem 5%;
  font-size: 1.5rem;
  text-align: center;
}
.meat-card-description {
  margin: 0.5rem 5%;
}
.meat-card-quantity {
  margin: 0.5rem 5%;
}
.meat-card-price {
  margin: 0.5rem 5%;
}
