
// @media (max-width: 5000px) and (min-width:4001px) {

// .homecard-container {
//   width: 70rem;
//   border-radius: 1rem;
//   box-shadow: 0px 4px 4px #999;
//   display: flex;
//   flex-direction: column;
//   margin: 0.5rem;
//   background-color: #637f81;
//   height: fit-content;
 
// }

// .homecard-img {
//   width: 100%;
//   border-radius: 1rem 1rem 0 0;
// }

// .homecard-title, .homecard-description {
//   margin: 0.5rem 5%;
// }

// .homecard-btn {
//   background-color: #1e5b60;
//   color: #637f81;
//   width: 60rem;
//   align-self: center;
//   border-radius: 25px;
//   margin-bottom: 2%;
//   font-size: 3rem;
// }
// h1 {
//   font-size: 5rem;
// }
// .homecard-description {
//   font-size: 3rem;
// }

// }
// @media (max-width: 4000px) and (min-width:3501px) {

//   .homecard-container {
//     width: 38rem;
//     border-radius: 1rem;
//     box-shadow: 0px 4px 4px #999;
//     display: flex;
//     flex-direction: column;
//     margin: 0.5rem;
//     // background-color: #637f81;
//     background-color: rgb(24, 7, 134);
//     height: fit-content;
   
//   }
  
//   .homecard-img {
//     width: 100%;
//     border-radius: 1rem 1rem 0 0;
//   }
  
//   .homecard-title, .homecard-description {
//     margin: 0.5rem 5%;
//   }
  
//   .homecard-btn {
//     background-color: #1e5b60;
//     color: #637f81;
//     width: 60rem;
//     align-self: center;
//     border-radius: 25px;
//     margin-bottom: 2%;
//     font-size: 3rem;
//   }
//   h1 {
//     font-size: 5rem;
//   }
//   .homecard-description {
//     font-size: 3rem;
//   }
  
//   }
// @media (max-width: 3500px) and (min-width:3301px) {

//   .homecard-container {
//     width: 70rem;
//     border-radius: 1rem;
//     box-shadow: 0px 4px 4px #999;
//     display: flex;
//     flex-direction: column;
//     margin: 0.5rem;
//     // background-color: #637f81;
//     background-color: rosybrown;
//     height: fit-content;
   
//   }
  
//   .homecard-img {
//     width: 100%;
//     border-radius: 1rem 1rem 0 0;
//   }
  
//   .homecard-title, .homecard-description {
//     margin: 0.5rem 5%;
//   }
  
//   .homecard-btn {
//     background-color: #1e5b60;
//     color: #637f81;
//     width: 60rem;
//     align-self: center;
//     border-radius: 25px;
//     margin-bottom: 2%;
//     font-size: 3rem;
//   }
//   h1 {
//     font-size: 5rem;
//   }
//   .homecard-description {
//     font-size: 3rem;
//   }
  
//   }
// @media (max-width: 3300px) and (min-width:3001px) {

//   .homecard-container {
//     width: 70rem;
//     border-radius: 1rem;
//     box-shadow: 0px 4px 4px #999;
//     display: flex;
//     flex-direction: column;
//     margin: 0.5rem;
//     // background-color: #637f81;
//     background-color: palevioletred;
//     height: fit-content;
    
//   }
  
//   .homecard-img {
//     width: 100%;
//     border-radius: 1rem 1rem 0 0;
//   }
  
//   .homecard-title, .homecard-description {
//     margin: 0.5rem 5%;
//   }
  
//   .homecard-btn {
//     background-color: #1e5b60;
//     color: #637f81;
//     width: 60rem;
//     align-self: center;
//     border-radius: 25px;
//     margin-bottom: 2%;
//     font-size: 3rem;
//   }
//   h1 {
//     font-size: 5rem;
//   }
//   .homecard-description {
//     font-size: 3rem;
//   }
  
//   }
// @media (max-width: 3000px) and (min-width:2501px) {

//   .homecard-container {
//     width: 35rem;
//     border-radius: 1rem;
//     box-shadow: 0px 4px 4px #999;
//     display: flex;
//     flex-direction: column;
//     margin: 0.5rem;
//     // background-color: #637f81;
//     background-color: #0ddae9;
//     height: fit-content;
   
//   }
  
//   .homecard-img {
//     width: 100%;
//     border-radius: 1rem 1rem 0 0;
//   }
  
//   .homecard-title, .homecard-description {
//     margin: 0.5rem 5%;
//   }
  
//   .homecard-btn {
//     background-color: #1e5b60;
//     color: #637f81;
//     width: 60rem;
//     align-self: center;
//     border-radius: 25px;
//     margin-bottom: 2%;
//     font-size: 3rem;
//   }
//   h1 {
//     font-size: 5rem;
//   }
//   .homecard-description {
//     font-size: 3rem;
//   }
  
//   }
.homecard-container {
  width: 20rem;

  border-radius: 1rem;
  box-shadow: 0px 4px 4px #999;
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  background-color: #637f81;
  // background-color: #6c0645;
  height: fit-content;
}

.homecard-img {
  width: 100%;
  border-radius: 1rem 1rem 0 0;
}

.homecard-title, .homecard-description {
  margin: 0.5rem 5%;
}

.homecard-btn {
  background-color: #1e5b60;
  color: #637f81;
  width: 80%;
  align-self: center;
  border-radius: 25px;
  margin-bottom: 2%;
}
//New IMAC
@media (max-width: 2500px) and (min-width:2201px) {
  .homecard-container {
    width: 41.5rem;
  
    border-radius: 1rem;
    box-shadow: 0px 4px 4px #999;
    display: flex;
    flex-direction: column;
    // margin: 0.2rem;
    background-color: #637f81;
    // background-color: peru;
    height: fit-content;
    // color: #6c0645;
  }
  
  .homecard-img {
    width: 100%;
    border-radius: 1rem 1rem 0 0;
  }
  
  .homecard-title, .homecard-description {
    margin: 0.5rem 5%;

  }
    .homecard-btn {
    background-color: #1e5b60;
    color: #637f81;
    width: 20rem;
    align-self: center;
    border-radius: 25px;
    margin-bottom: 2%;
    font-size: 3rem;
  }
  h1 {
    font-size: 5rem;
  }
  .homecard-description {
    font-size: 3rem;
  }
  
}
@media (max-width: 800px) {
  .homecard-container {
    width: 40rem;
    // margin-left: -8%;
  
  }
  .homecard-btn {
    height: 2rem;
    font-size: 1.5rem;
  }
  .homecard-title, .homecard-description {
    font-size: 1.5rem;
  }

}
@media (max-width: 600px) {
  .homecard-container {
    width: 20rem;
    margin-left: -8%;
  
  }
  .homecard-btn {
    height: 2rem;
    font-size: 1.5rem;
  }
  .homecard-title, .homecard-description {
    font-size: 1.5rem;
  }

}
@media (max-width: 400px) {
  .homecard-container {
    width: 18rem;
    margin-left: -8%;
  
  }
  .homecard-btn {
    height: 2rem;
    font-size: 1.5rem;
  }
  .homecard-title, .homecard-description {
    font-size: 1.5rem;
  }

}
  
//extra screen hooked to small macbook
// @media (max-width: 2200px) and (min-width:2001px) {
//   .homecard-container {
//     width: 42rem;
  
//     border-radius: 1rem;
//     box-shadow: 0px 4px 4px #999;
//     display: flex;
//     flex-direction: column;
//     margin: 0.5rem;
//     // background-color: #637f81;
//     background-color: #6c0645;
//     height: fit-content;
//   }
  
//   .homecard-img {
//     width: 100%;
//     border-radius: 1rem 1rem 0 0;
//   }
  
//   .homecard-title, .homecard-description {
//     margin: 0.5rem 5%;
//   }
  
//   .homecard-btn {
//     background-color: #1e5b60;
//     color: #637f81;
//     width: 80%;
//     align-self: center;
//     border-radius: 25px;
//     margin-bottom: 2%;
//   }
// }
//small macbook
// @media (max-width: 2000px) and (min-width:1201px) {
//   .homecard-container {
//     width: 20rem;
  
//     border-radius: 1rem;
//     box-shadow: 0px 4px 4px #999;
//     display: flex;
//     flex-direction: column;
//     margin: 0.5rem;
//     background-color: #637f81;
//     // background-color: #6c0645;
//     height: fit-content;
//   }
  
//   .homecard-img {
//     width: 100%;
//     border-radius: 1rem 1rem 0 0;
//   }
  
//   .homecard-title, .homecard-description {
//     margin: 0.5rem 5%;
//   }
  
//   .homecard-btn {
//     background-color: #1e5b60;
//     color: #637f81;
//     width: 80%;
//     align-self: center;
//     border-radius: 25px;
//     margin-bottom: 2%;
//   }
// }
// @media (max-width: 1200px) {
//   .homecard-container {
//     width: 10rem;
  
//     border-radius: 1rem;
//     box-shadow: 0px 4px 4px #999;
//     display: flex;
//     flex-direction: column;
//     margin: 0.5rem;
//     // background-color: #637f81;
//     background-color: #6c0645;
//     height: fit-content;
//   }
  
//   .homecard-img {
//     width: 100%;
//     border-radius: 1rem 1rem 0 0;
//   }
  
//   .homecard-title, .homecard-description {
//     margin: 0.5rem 5%;
//   }
  
//   .homecard-btn {
//     background-color: #1e5b60;
//     color: #637f81;
//     width: 80%;
//     align-self: center;
//     border-radius: 25px;
//     margin-bottom: 2%;
//   }
// }
