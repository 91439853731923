.alaskaactualmap {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  display: flex;
  justify-content: center;
  
}
map {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  display: flex;
  justify-content: center;
  
}
.actualmap {
  width: 100%;
}
.maphero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  // height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  background-color: #333;
}
// .pigeon-tiles {
//   background-color: bisque;
// }
// .h1 {
//   margin-left: 20px;
//   margin-bottom: -55px;
// }
.pigeon-tiles-box {
  background-color: none;
  // width: 8800px;
  position: fixed;
  // height: 100%;
}
.wool_info {
  margin-left: 2%;
}
.micron {
  margin-top: -15%;
}
.maphero-container > h1 {
  color: rgb(59, 109, 156);
  font-size: 80px;
  margin-top: -100px;
}
.maphero-container > h2 {
  color: rgb(59, 109, 156);
  font-size: 80px;
  margin-top: -100px;
}

.maphero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.maphero-btns {
  margin-top: 32px;
}

.maphero-btns .btn {
  margin: 6px;
}

.mapfa-play-circle {
  margin-left: 4px;
}
.another_part {
  padding: 3rem;
}
@media screen and (max-width: 960px) {
  .maphero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
  .maphero-container > h2 {
    font-size: 40px;
    margin-top: -150px;
  }
  .actualmap {
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  .actualmap {
    width: 50%;
  }
  .maphero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }
  .maphero-container > h2 {
    font-size: 30px;
    margin-top: -100px;
  }

  .maphero-container > p {
    font-size: 30px;
  }

  .mapbtn-mobile {
    display: block;
    text-decoration: none;
  }

  .mapbtn {
    width: 50%;
  }
}
