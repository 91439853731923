@media (max-width: 5000px) and (min-width:3001px) {

  .bstockcard-container {
    width: 70rem;
    border-radius: 1rem;
    box-shadow: 0px 4px 4px #999;
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    background-color: #637f81;
    height: fit-content;
   
  }
  
  .bstockcard-img {
    width: 100%;
    border-radius: 1rem 1rem 0 0;
  }
  
  .bstockcard-title, .bstockcard-description {
    margin: 0.5rem 5%;
  }
  
  .bstockcard-btn {
    background-color: #1e5b60;
    color: #637f81;
    width: 60rem;
    align-self: center;
    border-radius: 25px;
    margin-bottom: 2%;
    font-size: 3rem;
  }
  h1 {
    font-size: 5rem;
  }
  .bstockcard-description {
    font-size: 3rem;

  }
}
@media (max-width: 3000px) and (min-width:2601px) {

  .bstockcard-container {
    width: 70rem;
    border-radius: 1rem;
    box-shadow: 0px 4px 4px #999;
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    background-color: #637f81;
    height: fit-content;
   
  }
  
  .bstockcard-img {
    width: 100%;
    border-radius: 1rem 1rem 0 0;
  }
  
  .bstockcard-title, .bstockcard-description {
    margin: 0.5rem 5%;
  }
  
  .bstockcard-btn {
    background-color: #1e5b60;
    color: #637f81;
    width: 60rem;
    align-self: center;
    border-radius: 25px;
    margin-bottom: 2%;
    font-size: 3rem;
  }
  h1 {
    font-size: 5rem;
  }
  .bstockcard-description {
    font-size: 3rem;

  }
}
@media (max-width: 2600px) {
  .bstockcard-container {
    width: 20rem;
  
    border-radius: 1rem;
    box-shadow: 0px 4px 4px #999;
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    background-color: #637f81;

    height: fit-content;
  }
  
  .bstockcard-img {
    width: 100%;
    border-radius: 1rem 1rem 0 0;
  }
  
  .bstockcard-title, .bstockcard-description {
    margin: 0.5rem 5%;
  }
  
  .bstockcard-btn {
    background-color: #1e5b60;
    color: #637f81;
    width: 80%;
    align-self: center;
    border-radius: 25px;
    margin-bottom: 2%;
  }
}
@media (max-width: 800px) {
  .bstockcard-container {
    width: 40rem;

  
  }
  .bstockcard-btn {
    height: 2rem;
    font-size: 1.5rem;
  }
  .bstockcard-title, .bstockcard-description {
    font-size: 1.5rem;
  }

}
@media (max-width: 600px) {
  .bstockcard-container {
    width: 15rem;
    margin-left: -8%;
  }
  .bstockcard-btn {
    height: 2rem;
    font-size: 1.5rem;
  }
  .bstockcard-title, .bstockcard-description {
    font-size: 1.5rem;
  }
}