// @media (max-width: 5000px) and (min-width:3001px) {
//   * {
//     box-sizing: border-box;
//     margin: 0;
//     padding: 0;
//     font-family: "PT Sans", sans-serif;
//   }
// .home-wrapper {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 100vw;
//     height: 60vh;
//     font-size: 3.6rem;
//     color: #6c8cb5;
// }
 
// .main-wrapper {
//   align-items: center;
//   align-content: center;
//   align-self: center;
//   background-color: #333;
//   color: bisque;
// }

// .home-top {
//   background-color: #1e5b60;
//   text-align: center;
//   align-items: center;
//   align-content: center;
//   color: bisque;
//   padding: 0.5%;
//   justify-self: center;
//   justify-content: center;
//   justify-items: center;
// }

// .home-cardWrapper {
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   grid-gap: 2%;
//   margin-left: 7%;
//   margin-right: 7%;
//   margin-top: 2%;
//   margin-bottom: 2%;
// }
// h2 {
//   font-size: 3rem;
// }
// }
// @media (max-width: 3000px) and (min-width:2001px) {
//   * {
//     box-sizing: border-box;
//     margin: 0;
//     padding: 0;
//     font-family: "PT Sans", sans-serif;
//   }
// .home-wrapper {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 100vw;
//     height: 60vh;
//     font-size: 3.6rem;
//     color: #6c8cb5;
// }
 
// .main-wrapper {
//   align-items: center;
//   align-content: center;
//   align-self: center;
//   background-color: #333;
//   color: bisque;
// }

// .home-top {
//   background-color: #1e5b60;
//   text-align: center;
//   align-items: center;
//   align-content: center;
//   color: bisque;
//   padding: 0.5%;
//   justify-self: center;
//   justify-content: center;
//   justify-items: center;
// }

// .home-cardWrapper {
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   grid-gap: 2%;
//   margin-left: 7%;
//   margin-right: 7%;
//   margin-top: 2%;
//   margin-bottom: 2%;
// }
// h2 {
//   font-size: 3rem;
// }
// }
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}
.home-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 60vh;
  font-size: 3.6rem;
  color: #6c8cb5;
}

.main-wrapper {
align-items: center;
align-content: center;
align-self: center;
background-color: #333;
color: bisque;
}

.home-top {
background-color: #1e5b60;
text-align: center;
align-items: center;
align-content: center;
color: bisque;
padding: 0.5%;
justify-self: center;
justify-content: center;
justify-items: center;
}

.home-cardWrapper {
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-gap: 1%;
margin-left: 7%;
margin-right: 7%;
margin-top: 2%;
margin-bottom: 2%;

}

//imac//
@media (max-width: 2500px) and (min-width:2201px) {
  .home-cardWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2%;
    margin-left: 3%;
    margin-right: 7%;
    margin-top: 2%;
    margin-bottom: 2%;
  }
}

@media (max-width: 1200px) {
  * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-family: "PT Sans", sans-serif;
    }
  .home-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 60vh;
      font-size: 3.6rem;
      color: #6c8cb5;
  }
   
  .main-wrapper {
    align-items: center;
    align-content: center;
    align-self: center;
    background-color: #333;
    color: bisque;
  }
  
  .home-top {
    background-color: #1e5b60;
    text-align: center;
    align-items: center;
    align-content: center;
    color: bisque;
    padding: 0.5%;
    justify-self: center;
    justify-content: center;
    justify-items: center;
  }
  
  .home-cardWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1%;
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 2%;
    margin-bottom: 2%;
  
  }
  }
  @media (max-width: 800px) {
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: "PT Sans", sans-serif;
      }
    .home-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 60vh;
        font-size: 3.6rem;
        color: #6c8cb5;
    }
     
    .main-wrapper {
      align-items: center;
      align-content: center;
      align-self: center;
      background-color: #333;
      color: bisque;
    }
    
    .home-top {
      background-color: #1e5b60;
      text-align: center;
      align-items: center;
      align-content: center;
      color: bisque;
      padding: 0.5%;
      justify-self: center;
      justify-content: center;
      justify-items: center;
    }
    
    .home-cardWrapper {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1%;
      margin-left: 7%;
      margin-right: 7%;
      margin-top: 2%;
      margin-bottom: 2%;
    
    }
    }
    @media (max-width: 600px) {

      .home-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100vw;
          height: 60vh;
          font-size: 3.6rem;
          color: #6c8cb5;
      }
       
      .main-wrapper {
        align-items: center;
        align-content: center;
        align-self: center;
        background-color: #333;
        color: bisque;
      }
      
      .home-top {
        background-color: #1e5b60;
        text-align: center;
        align-items: center;
        align-content: center;
        color: bisque;
        padding: 0.5%;
        justify-self: center;
        justify-content: center;
        justify-items: center;
      }
      
      .home-cardWrapper {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1%;
        margin-left: 12%;
        margin-right: 7%;
        margin-top: 2%;
        margin-bottom: 20%;
        justify-items: center;
      
      }
      }
//imac
// @media (max-width: 2500px) and (min-width:2201px) {
//   .home-cardWrapper {
//     display: grid;
//     grid-template-columns: repeat(3, 1fr);
//     grid-gap: 2%;
//     margin-left: 3%;
//     margin-right: 7%;
//     margin-top: 2%;
//     margin-bottom: 2%;
//   }
// }
// @media (max-width: 2000px) {
//   * {
//       box-sizing: border-box;
//       margin: 0;
//       padding: 0;
//       font-family: "PT Sans", sans-serif;
//     }
//   .home-wrapper {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       width: 100vw;
//       height: 60vh;
//       font-size: 3.6rem;
//       color: #6c8cb5;
//   }
   
//   .main-wrapper {
//     align-items: center;
//     align-content: center;
//     align-self: center;
//     background-color: #333;
//     color: bisque;
//   }
  
//   .home-top {
//     background-color: #1e5b60;
//     text-align: center;
//     align-items: center;
//     align-content: center;
//     color: bisque;
//     padding: 0.5%;
//     justify-self: center;
//     justify-content: center;
//     justify-items: center;
//   }
  
//   .home-cardWrapper {
//     display: grid;
//     grid-template-columns: repeat(3, 1fr);
//     grid-gap: 1%;
//     margin-left: 7%;
//     margin-right: 7%;
//     margin-top: 2%;
//     margin-bottom: 2%;
  
//   }
//   }
//moms ipad
// @media (max-width: 1200px) {
//   * {
//       box-sizing: border-box;
//       margin: 0;
//       padding: 0;
//       font-family: "PT Sans", sans-serif;
//     }
//   .home-wrapper {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       width: 100vw;
//       height: 60vh;
//       font-size: 3.6rem;
//       color: #6c8cb5;
//   }
    
//   .main-wrapper {
//     align-items: center;
//     align-content: center;
//     align-self: center;
//     background-color: #f60a0a;
//     color: bisque;
//   }
  
//   .home-top {
//     background-color: #1e5b60;
//     text-align: center;
//     align-items: center;
//     align-content: center;
//     color: bisque;
//     padding: 0.5%;
//     justify-self: center;
//     justify-content: center;
//     justify-items: center;
//   }
  
//   .home-cardWrapper {
//     display: grid;
//     grid-template-columns: repeat(3, 1fr);
//     grid-gap: 1%;
//     margin-left: 7%;
//     margin-right: 7%;
//     margin-top: 2%;
//     margin-bottom: 2%;
  
//   }
//   }
  
// @media (max-width:1000) {
//   .main-wrapper {
//     align-items: center;
//     align-content: center;
//     align-self: center;
//     background-color: #530606;
//     color: bisque;
//   }
// }
// @media (max-width:800) {
//   .main-wrapper {
//     align-items: center;
//     align-content: center;
//     align-self: center;
//     background-color: #efc90d;
//     color: bisque;
//   }
// }
// @media (max-width:600) {
//   .main-wrapper {
//     align-items: center;
//     align-content: center;
//     align-self: center;
//     background-color: #31e216;
//     color: bisque;
//   }
// }
// @media (max-width:400) {
//   .main-wrapper {
//     align-items: center;
//     align-content: center;
//     align-self: center;
//     background-color: #0dbeee;
//     color: bisque;
//   }
// }
// @media (max-width:300) {
//   .main-wrapper {
//     align-items: center;
//     align-content: center;
//     align-self: center;
//     background-color: #1e09d9;
//     color: bisque;
//   }
// }
// @media (max-width:200) {
//   .main-wrapper {
//     align-items: center;
//     align-content: center;
//     align-self: center;
//     background-color: #e50e9a;
//     color: bisque;
//   }
// }