
.alaskamap {
    object-fit: cover;
    width: 40%;
    height: 40%;
    position: fixed;
    z-index: -1;
    display: flex;
    justify-content: right;
    
  }
  .alaskaactualmap {
    width: 100%;
  }
  .alaskamaphero-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    // height: 80vh;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    background-color: blue;
    padding-bottom: 5%;
  }
  // .pigeon-tiles {
  //   background-color: bisque;
  // }
  // .h1 {
  //   margin-left: 20px;
  //   margin-bottom: -55px;
  // }
  .pigeon-tiles-box {
    background-color: none;
    // width: 8800px;
    position: fixed;
    // height: 100%;
  }
  .wool_info {
    margin-left: 2%;
  }
  .micron {
    margin-top: -15%;
  }
  .alaskamaphero-container > h1 {
    color: rgb(59, 109, 156);
    font-size: 80px;
    margin-top: -100px;
  }
  .alaskamaphero-container > h2 {
    color: rgb(59, 109, 156);
    font-size: 80px;
    margin-top: -100px;
  }
  
  .alaskamaphero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }
  
  .maphero-btns {
    margin-top: 32px;
  }
  
  .maphero-btns .btn {
    margin: 6px;
  }
  
  .mapfa-play-circle {
    margin-left: 4px;
  }
  .another_part {
    padding: 3rem;
  }
  @media screen and (max-width: 960px) {
    .alaskamaphero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
    .alaskamaphero-container > h2 {
      font-size: 40px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .alaskamaphero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
    .alaskamaphero-container > h2 {
      font-size: 30px;
      margin-top: -100px;
    }
  
    .alaskamaphero-container > p {
      font-size: 30px;
    }
  
    .mapbtn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .mapbtn {
      width: 100%;
    }
  }
  