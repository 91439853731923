.winner-card-container {
    width: 20rem;
    border-radius: 1rem;
    box-shadow: 0px 10px 8px #999;
    display: flex;
    flex-direction: column;
    margin: 2rem;
    background-color: white;
    height: 35rem;
    justify-content: center;
  }
  
  .winner-card-img {
    width: 100%;
    height: fit-content;
    border-radius: 1rem 1rem 0 0;
  }
  
  .winner-card-title {
    margin: 1rem 5%;
    font-size: 1.5rem;
    text-align: center;

  }
  .winner-card-description {
    margin: 0.5rem 5%;
    text-align: justify;
    height: 12rem;
  }


  @media only screen and (max-width: 1206px) {

    .winner-card-container {
      width: 21rem;
      border-radius: 1rem;
      box-shadow: 0px 10px 8px #999;
      display: flex;
      flex-direction: column;
      margin: 0.5rem;
      background-color: white;
      height: 37rem;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 1156px) {

    .winner-card-container {
      width: 20rem;
      border-radius: 1rem;
      box-shadow: 0px 10px 8px #999;
      display: flex;
      flex-direction: column;
      margin: 0.5rem;
      background-color: white;
      height: 37rem;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 1103px) {

    .winner-card-container {
      width: 18rem;
      border-radius: 1rem;
      box-shadow: 0px 10px 8px #999;
      display: flex;
      flex-direction: column;
      margin: 0.5rem;
      background-color: white;
      height: 37rem;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 1000px) {

    .winner-card-container {
      width: 15rem;
      border-radius: 1rem;
      box-shadow: 0px 10px 8px #999;
      display: flex;
      flex-direction: column;
      margin: 0.5rem;
      background-color: white;
      height: 37rem;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 844px) {

    .winner-card-container {
      width: 13rem;
      border-radius: 1rem;
      box-shadow: 0px 10px 8px #999;
      display: flex;
      flex-direction: column;
      margin: 0.5rem;
      background-color: white;
      height: 37rem;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 739px) {

    .winner-card-container {
      width: 12rem;
      border-radius: 1rem;
      box-shadow: 0px 10px 8px #999;
      display: flex;
      flex-direction: column;
      margin: 0.5rem;
      background-color: white;
      height: 37rem;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 686px) {

    .winner-card-container {
      width: 11rem;
      border-radius: 1rem;
      box-shadow: 0px 10px 8px #999;
      display: flex;
      flex-direction: column;
      margin: 0.5rem;
      background-color: white;
      height: 37rem;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 579px) {

    .winner-card-container {
      width: 30rem;
      border-radius: 1rem;
      box-shadow: 0px 10px 8px #999;
      display: flex;
      flex-direction: column;
      margin: 0.5rem;
      background-color: white;
      height: 37rem;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 1103px) {

    .winner-card-container {
      width: 25rem;
      border-radius: 1rem;
      box-shadow: 0px 10px 8px #999;
      display: flex;
      flex-direction: column;
      margin: 0.5rem;
      background-color: white;
      height: 37rem;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 450px) {

    .winner-card-container {
      width: 23rem;
      border-radius: 1rem;
      box-shadow: 0px 10px 8px #999;
      display: flex;
      flex-direction: column;
      margin: 0.5rem;
      background-color: white;
      height: 37rem;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 409px) {

    .winner-card-container {
      width: 20rem;
      border-radius: 1rem;
      box-shadow: 0px 10px 8px #999;
      display: flex;
      flex-direction: column;
      margin: 0.5rem;
      background-color: white;
      height: 37rem;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 1103px) {

    .winner-card-container {
      width: 18rem;
      border-radius: 1rem;
      box-shadow: 0px 10px 8px #999;
      display: flex;
      flex-direction: column;
      margin: 0.5rem;
      background-color: white;
      height: 37rem;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 330px) {

    .winner-card-container {
      width: 15rem;
      border-radius: 1rem;
      box-shadow: 0px 10px 8px #999;
      display: flex;
      flex-direction: column;
      margin: 0.5rem;
      background-color: white;
      height: 37rem;
      justify-content: center;
    }
    .winner-card-description {
      margin: 0.5rem 5%;
      text-align: justify;
      height: 20rem;
    }
  }