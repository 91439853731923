
.footer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    font-weight: bold;
    height: 100%;
    background-color: bisque;
}
.footer-seperator {
    flex-grow: 1;
    border: none;
    height: 2px;
    background-color: black;
    width: 100%;
}

.footer-social-media {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 1%;
    padding-bottom: 1%;
    font-size: x-large;
    color: #1e5b60;
    align-items: center;
    margin-bottom: 20px;
    background-color: rgb(61, 118, 139);
}

.footer-social-media-icon a {
    margin: 0px 4px;
    color: #1e5b60;
    font-size: 3rem;
}

.footer-info {
    display: flex;
    justify-content: center;
}

.footer-logo {
    width: 25%;
}
.footer-info-left, .footer-info-center, .footer-info-right {
    font-size:large;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 30%;
    padding-top: 1%;
    color: #1e5b60;
}

.spam {
    font-size: small;
}

//imac
@media (max-width: 2500px) and (min-width:2201px) {
    .footer-social-media {
        font-size: 4.5rem;
    }
    .footer-social-media-icon a {
        margin: 0px 4px;
        color: #1e5b60;
        font-size: 4rem;
    }
    .footer-info-left, .footer-info-center, .footer-info-right {
        font-size:3rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        width: 30%;
        padding-top: 1%;
        color: #1e5b60;
        justify-content: center;
    }
    .spam {
        font-size: 2rem;
    }
    .footer-logo {
        width: 40%;
    }
}
// @media screen and (max-width: 3000px) {
// .footer {
//     display: flex;
//     flex-direction: column;
//     margin-bottom: 20px;
//     font-weight: bold;
//     height: 100%;
//     background-color: bisque;
// }
// .footer-seperator {
//     flex-grow: 1;
//     border: none;
//     height: 2px;
//     background-color: black;
//     width: 100%;
// }

// .footer-social-media {
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     padding-top: 1%;
//     padding-bottom: 1%;
//     font-size: x-large;
//     color: #1e5b60;
//     align-items: center;
//     margin-bottom: 20px;
//     background-color: rgb(61, 118, 139);

// }
// .footer-social-media-icon a {
//     margin: 0px 4px;
//     color: #1e5b60;
//     font-size: 3rem;
// }

// .footer-info {
//     display: flex;
//     justify-content: center;
// }

// .footer-logo {
//     width: 25%;
// }
// .footer-info-left, .footer-info-center, .footer-info-right {
//     font-size:large;
//     display: flex;
//     flex-direction: column;
//     margin-bottom: 10px;
//     width: 30%;
//     padding-top: 1%;
//     color: #1e5b60;
// }

// .spam {
//     font-size: small;
// }
// }
@media screen and (max-width: 850px) {
    .footer-social-media {
        font-size: 1rem;
    }
    .footer-social-media-icon a {
        margin: 0px 4px;
        color: #1e5b60;
        font-size: 2rem;
    }
    .footer-info-left, .footer-info-center, .footer-info-right {
        font-size:30%;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        width: 30%;
        padding-top: 1%;
        color: #1e5b60;
    }
    .spam {
        font-size: 20%;
    }
}

@media screen and (max-width: 476px) {

    .footer-social-media {
        font-size: .5rem;
    }
    .footer-social-media-icon a {
        margin: 0px 4px;
        color: #1e5b60;
        font-size: 1rem;
    }
    .footer-info-left, .footer-info-center, .footer-info-right {
        font-size:20%;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        width: 30%;
        padding-top: 1%;
        color: #1e5b60;
    }
    .spam {
        font-size: 10%;
    }
}

@media screen and (max-width: 420px) {

    .footer-info-left, .footer-info-center, .footer-info-right {
        font-size:10%;
        margin-bottom: 10px;
        width: 33%;
        padding-top: 1%;
        color: #1e5b60;
    }
    .spam {
        font-size: 8%;
    }
}