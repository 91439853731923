// .about-top {
//   padding-left: 5%;
//   padding-right: 5%;
//   text-align: justify;
// }
// .about-cards-wrapper {
//   width: 80%;
//   padding-left: 5%;
//   padding-right: 5%;
//   padding-bottom: 2%;
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);
//   grid-gap: 2%;

// }
// // @media only screen and (max-width: 1215px) {
// //   .about-cards-wrapper {
// //       width: 80%;
// //       padding-left: 5%;
// //       padding-right: 5%;
// //       display: grid;
// //       grid-template-columns: repeat(3, 1fr);
// //       grid-gap: 2%;
    
// //   }
// // }
// @media only screen and (max-width: 1206px) {
//   .about-cards-wrapper {
//       width: 80%;
//       padding-left: 5%;
//       padding-right: 5%;
//       display: grid;
//       grid-template-columns: repeat(3, 1fr);
//       grid-gap: 2%;
//       justify-items: center;
//       padding-bottom: 25%;
    
//   }
//   .about-card-container {
//     width: 10rem;
//     border-radius: 1rem;
//     box-shadow: 0px 10px 8px #999;
//     display: flex;
//     flex-direction: column;
//     margin: 0.5rem;
//     background-color: white;
//     height: 75rem;
//     justify-content: center;
    
//   }
// }
// @media only screen and (max-width: 945px) {
//   .about-cards-wrapper {
//     padding-bottom: 5%;
  
// }}

// @media only screen and (max-width: 590px) {
//   .about-cards-wrapper {
//       grid-template-columns: repeat(1, 1fr);
//       padding-bottom: 25%;
    
//   }
// }
// @media only screen and (max-width: 330px) {
//   .about-cards-wrapper {
//     padding-bottom: 25%;
//   }
// }
@media (max-width: 5000px) and (min-width:2001px) {
  p {
  font-size: 3rem;
  }
  * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-family: "PT Sans", sans-serif;
    }
  .about-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 60vh;
      font-size: 3.6rem;
      color: #6c8cb5;
  }
   
  .about-main-wrapper {
    align-items: center;
    align-content: center;
    align-self: center;
    background-color: #333;
    color: bisque;
    padding: 2%;
  }
  
  .about-top {
    background-color: #1e5b60;
    text-align: center;
    align-items: center;
    align-content: center;
    color: bisque;
    padding: 0.5%;
    justify-self: center;
    justify-content: center;
    justify-items: center;
  }
  
  .about-cardWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1%;
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 2%;
    margin-bottom: 2%;
  
  }
}
  

 

    @media (max-width: 2000px) and (min-width: 2051px) {
      * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          font-family: "PT Sans", sans-serif;
        }
      .about-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100vw;
          height: 60vh;
          font-size: 3.6rem;
          color: #6c8cb5;
      }
        
      .about-main-wrapper {
        align-items: center;
        align-content: center;
        align-self: center;
        background-color: #3ccf01;
        color: rgb(29, 107, 232);
      }
      
      .about-top {
        background-color: #1e5b60;
        text-align: center;
        align-items: center;
        align-content: center;
        color: bisque;
        padding: 0.5%;
        justify-self: center;
        justify-content: center;
        justify-items: center;
      }
      
      .about-cardWrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1%;
        margin-left: 7%;
        margin-right: 7%;
        margin-top: 2%;
        margin-bottom: 2%;
      
      }
      }
      @media (max-width: 2050px) and (min-width: 2001px) {
        * {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            font-family: "PT Sans", sans-serif;
          }
        .about-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100vw;
            height: 60vh;
            font-size: 3.6rem;
            color: #6c8cb5;
        }
          
        .about-main-wrapper {
          align-items: center;
          align-content: center;
          align-self: center;
          background-color: #ed2525;
          color: rgb(29, 107, 232);
        }
        
        .about-top {
          background-color: #1e5b60;
          text-align: center;
          align-items: center;
          align-content: center;
          color: bisque;
          padding: 0.5%;
          justify-self: center;
          justify-content: center;
          justify-items: center;
        }
        
        .about-cardWrapper {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 1%;
          margin-left: 7%;
          margin-right: 7%;
          margin-top: 2%;
          margin-bottom: 2%;
        
        }
        }
        
       
          //my ipad vertical//
@media (max-width: 1500px) and (min-width: 1301px) {
  .about-main-wrapper {
    align-items: center;
    align-content: center;
    align-self: center;
    background-color: #333;
    color: bisque;
  }
  }
  //mini laptop//
@media (max-width: 1300px) and (min-width: 1201px) {
  * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-family: "PT Sans", sans-serif;
    }
  .about-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 60vh;
      font-size: 3.6rem;
      color: #6c8cb5;
  }
    
  .about-main-wrapper {
    align-items: center;
    align-content: center;
    align-self: center;
    background-color: #333;
    color: bisque;
  }
  
  .about-top {
    background-color: #1e5b60;
    text-align: center;
    align-items: center;
    align-content: center;
    color: bisque;
    padding: 0.5%;
    justify-self: center;
    justify-content: center;
    justify-items: center;
  }
  
  .about-cardWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1%;
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 2%;
    margin-bottom: 2%;
  
  }
  }

@media (max-width: 1200px) and (min-width: 1101px) {
  * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-family: "PT Sans", sans-serif;
    }
  .about-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 60vh;
      font-size: 3.6rem;
      color: #6c8cb5;
  }
    
  .about-main-wrapper {
    align-items: center;
    align-content: center;
    align-self: center;
    background-color: #b1ed25;
    color: rgb(29, 107, 232);
  }
  
  .about-top {
    background-color: #1e5b60;
    text-align: center;
    align-items: center;
    align-content: center;
    color: bisque;
    padding: 0.5%;
    justify-self: center;
    justify-content: center;
    justify-items: center;
  }
  
  .about-cardWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1%;
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 2%;
    margin-bottom: 2%;
  
  }
  }
  //my ipad moms ipad vertically//
@media (max-width: 1100px) and (min-width: 1001px) {
  .about-main-wrapper {
    align-items: center;
    align-content: center;
    align-self: center;
    background-color: #333;;
    color: bisque;
  }
  }
@media (max-width: 1000px) and (min-width: 801px){
.about-main-wrapper {
  align-items: center;
  align-content: center;
  align-self: center;
  background-color: #333;
  color: bisque;
}
}
  //moms ipad //
@media (max-width: 800px) and (min-width: 751px) {
  .about-main-wrapper {
    align-items: center;
    align-content: center;
    align-self: center;
    background-color: #333;
    color: bisque;
  }
  }
  //moms phone verticall//
@media (max-width: 750px) and (min-width: 701px) {    
  .about-main-wrapper {
    align-items: center;
    align-content: center;
    align-self: center;
    background-color: #333;
    color: bisque;
  }
  }
  //my phone horizontal//
@media (max-width: 700px) and (min-width: 651px) {
  .about-main-wrapper {
    align-items: center;
    align-content: center;
    align-self: center;
    background-color: #333;
    color: bisque;
  }
  }
  //moms phone//
@media (max-width: 500px) and (min-width: 451px) {
    
  .about-main-wrapper {

    background-color: #333;
    color: bisque;
  }
} 
//my phone//
@media (max-width: 450px) and (min-width: 351px) {
    
  .about-main-wrapper {

    background-color: #333;
    color: bisque;
  }
} 
