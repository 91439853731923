* {
    box-sizing: border-box;
}
body {
    margin: 0;
}

.container {
    margin: 1rem;
    text-align: center;
}