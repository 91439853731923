// .hero-container {
// width: 100%;
// overflow: hidden;
// // background-color: red;
// position: relative;

// }  
// video {
//    /* object-fit: cover; */
//     overflow:hidden;
//     /* height: auto; */
//    /* width: 100%; */
// }

// .hero-container > h1 {
//    margin-top: -100px;
// }

// .hero-container > h2 {
//    margin-top: -100px;
// }
// .hero-container > h2 {
//    margin-top: -100px;
// }
// .hero-container > p {
//    font-size: 32px;
// }

video {
   object-fit: cover;
   width: 100%;
   height: 100%;
  //  opacity: 0%;
  //  position: fixed;
   z-index: -1;
 }
 
 .hero-container {
   /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
   object-fit: contain;
 }
//  .h1 {
//    color: rgb(59, 109, 156);
//  }
//  h3 {
//   background-color: #2e5e82;
//   opacity: 60%;
//   color: #fff;
// }
 .hero-container > h1 {
   color: rgb(59, 109, 156);
   font-size: 3rem;
  //  margin-top: 35%;
  //  padding-right: 31%;
 }
 .hero-container > h2 {
   color: rgb(59, 109, 156) 40%;
   font-size: 2rem;
  //  margin-top: -100px;
 }
 
 .hero-container > p {
   margin-top: 8%;
   color: rgb(59, 109, 156) 40%;
   font-size: 2rem;
   font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
     "Lucida Sans", Arial, sans-serif;
 }
 
//  .hero-btns {
//    margin-top: 32%;
//  }
 
//  .hero-btns .btn {
//    margin: 6%;
//  }
 
//  .fa-play-circle {
//    margin-left: 4%;
//  }
 @media screen and (max-height: 1368px) {
  .hero-container > h1 {
    // font-size: 50px;
    margin-top: 3%;
  }
}
// @media screen and (max-width: 1280px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: -20%;
//   }
// }
//  @media screen and (max-width: 1240px) {
//   .hero-container > h1 {
//     font-size: 50px;
//     margin-top: 55vh;
//   }
//   .hero-container > h2 {
//     font-size: 40px;
//     margin-top: -150px;
//   }
// }
// @media screen and (max-width: 1180px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: -20%;
//   }
// }
// @media screen and (max-width: 1024px) {
//   .hero-container > h1 {
//     font-size: 40px;
//     margin-top: 5vh;
//   }
// }
//  @media screen and (max-width: 960px) {
//    .hero-container > h1 {
//      font-size: 30px;
//      margin-top: -150px;
//    }
//    .hero-container > h2 {
//      font-size: 40px;
//      margin-top: -150px;
//    }
//  }
//  @media screen and (max-width: 932px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: 25%;
//   }
//   .hero-container > h2 {
//     font-size: 40px;
//     margin-top: -150px;
//   }
// }
// @media screen and (max-width: 912px) {
//   .hero-container > h1 {
//     font-size: 50px;
//     margin-top: -60%;
//   }
// }
//  @media screen and (max-width: 897px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: 20%;
//   }
// }
// @media screen and (max-width: 883px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: 30%;
//   }
// }
// @media screen and (max-width: 853px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: -80%;
//   }
// }
// @media screen and (max-width: 845px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: 30%;
//   }
// }
// @media screen and (max-width: 820px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: -60%;
//   }
// }
//  @media screen and (max-width: 768px) {
//    .hero-container > h1 {
//      font-size: 50px;
//      margin-top: -100px;
//    }
//    .hero-container > h2 {
//      font-size: 30px;
//      margin-top: -100px;
//    }
 
//    .hero-container > p {
//      font-size: 30px;
//    }
 
//    .btn-mobile {
//      display: block;
//      text-decoration: none;
//    }
 
//    .btn {
//      width: 100%;
//    }
//  }
//  @media screen and (max-width: 745px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: 30%;
//   }
// }
// @media screen and (max-width: 740px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: 30%;
//   }
// }
// @media screen and (max-width: 670px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: 50%;
//   }
// }
// @media screen and (max-width: 540px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: 10%;
//   }
// }
// @media screen and (max-width: 477px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: -100px;
//   }
//   // .hero-container > h2 {
//   //   font-size: 10px;
//   //   // margin-top: -100px;
//   // }
//   .hero-container > p {
//     font-size: 25px;
//   }
// }
 
//  @media screen and (max-width: 458px) {
//   .hero-container {
//     /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
//     height: 100vh;
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
//     object-fit: contain;
//   }
//   .hero-container > h1 {
//     font-size: 25px;
//     margin-top: -100px;
//   }
//   .hero-container > h2 {
//     font-size: 30px;
//     margin-top: -100px;
//   }

//   .hero-container > p {
//     font-size: 20px;
//   }

//   .btn-mobile {
//     display: block;
//     text-decoration: none;
//   }

//   .btn {
//     width: 100%;
//   }
// }
// @media screen and (max-width: 430px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: -50%;
//   }
// }
// @media screen and (max-width: 415px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: -50%;
//   }
// }
// @media screen and (max-width: 390px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: -20%;
//   }
// }
// @media screen and (max-width: 375px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: 20%;
//   }
// }
// @media screen and (max-width: 344px) {
//   .hero-container > h1 {
//     font-size: 30px;
//     margin-top: -40%;
//   }
// }