.winner-top {
  padding-left: 5%;
  padding-right: 5%;
  text-align: justify;
}
.winner-cards-wrapper {
  width: 80%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2%;

}
// @media only screen and (max-width: 1215px) {
//   .winner-cards-wrapper {
//       width: 80%;
//       padding-left: 5%;
//       padding-right: 5%;
//       display: grid;
//       grid-template-columns: repeat(3, 1fr);
//       grid-gap: 2%;
    
//   }
// }
@media only screen and (max-width: 1206px) {
  .winner-cards-wrapper {
      width: 80%;
      padding-left: 5%;
      padding-right: 5%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2%;
      justify-items: center;
      padding-bottom: 25%;
    
  }
  .winner-card-container {
    width: 10rem;
    border-radius: 1rem;
    box-shadow: 0px 10px 8px #999;
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    background-color: white;
    height: 37rem;
    justify-content: center;
    
  }
}
@media only screen and (max-width: 945px) {
  .winner-cards-wrapper {
    padding-bottom: 5%;
  
}}

@media only screen and (max-width: 590px) {
  .winner-cards-wrapper {
      grid-template-columns: repeat(1, 1fr);
      padding-bottom: 25%;
    
  }
}
@media only screen and (max-width: 330px) {
  .winner-cards-wrapper {
    padding-bottom: 25%;
  }
}