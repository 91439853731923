* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
}


.navbar {
  align-items: center;
  background-color: #1e5b60;;
  color: bisque;
  padding-left: 1rem;
}

.brand-title {
  font-size: 1.5rem;
  margin-right: 4.5rem;
}
.header-logo {
  width: 4rem;
  margin: .5rem;
}


header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 1rem;
  background-color: #1e5b60;
  color:bisque;
}

nav a {
  margin: 0 1rem;
  color: bisque;
  text-decoration: none;
}

nav a:hover {
  color: rgb(61, 118, 139);
  background-color: bisque;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  color: bisque;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}
//Imac Size
@media (max-width: 2500px) and (min-width:2201px) {

// .navbar {
//   align-items: center;
//   background-color: #1e5b60;;
//   color: bisque;
//   padding-left: 1rem;
// }
.brand-title {
  font-size: 4rem;
  // margin-left: -8.5rem;
  }
  .header-logo {
    width: 10rem;
    margin: .5rem;
  }
  // header {
  //   display: flex;
  //   height: 9rem;
  // }

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 9rem;
  padding: 0 1rem;
  background-color: #1e5b60;
  color:bisque;
}

nav a {
  margin: 0 1rem;
  color: bisque;
  text-decoration: none;
  font-size: 3rem;
}

nav a:hover {
  color: rgb(61, 118, 139);
  background-color: bisque;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  color: bisque;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}


}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;

  }
  header nav {
    position: fixed;
    top: 8%;
    right: 0;
    height: 65%;
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #637f81;
    transition: 1s;
    transform: translateY(-100vh);
  }
  header .responsive_nav {
    transform: none;
  }
  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  nav a {
    font-size: 1.6rem;
  }
    .brand-title {
    font-size: 1.5rem;
    // margin-left: 1.5rem;
  }
  .header-logo {
    width: 4rem;
    margin: .5rem;
  }
}

@media only screen and (max-width: 600px) {
  header nav {

    width: 65%;

  }
}



// * {
//   box-sizing: border-box;
//   padding: 0;
//   margin: 0;
//   box-sizing: border-box;
// }
// body {
//   margin: 0;
//   padding: 0;
// }
// .navbar {
//   align-items: center;
//   background-color: #1e5b60;;
//   color: bisque;
//   padding-left: 1rem;
// }
// .brand-title {
//   font-size: 5rem;
//   margin-left: -8.5rem;
//   }
//   .header-logo {
//     width: 10rem;
//     margin: .5rem;
//   }

// header {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   height: 9rem;
//   padding: 0 1rem;
//   background-color: #1e5b60;
//   color:bisque;
// }

// nav a {
//   margin: 0 1rem;
//   color: bisque;
//   text-decoration: none;
//   font-size: 3rem;
// }

// nav a:hover {
//   color: rgb(61, 118, 139);
//   background-color: bisque;
// }

// header .nav-btn {
//   padding: 5px;
//   cursor: pointer;
//   background-color: transparent;
//   border: none;
//   outline: none;
//   color: bisque;
//   visibility: hidden;
//   opacity: 0;
//   font-size: 1.8rem;
// }


// @media only screen and (max-width: 1024px) {
//   header .nav-btn {
//     visibility: visible;
//     opacity: 1;
//   }
//   header nav {
//     position: fixed;
//     top: 8%;
//     right: 0;
//     height: 65%;
//     width: 45%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     gap: 1rem;
//     background-color: #637f81;
//     transition: 1s;
//     transform: translateY(-100vh);
//   }
//   header .responsive_nav {
//     transform: none;
//   }
//   nav .nav-close-btn {
//     position: absolute;
//     top: 2rem;
//     right: 2rem;
//   }
//   nav a {
//     font-size: 3.6rem;
//   }
//     .brand-title {
//     font-size: 1.5rem;
//     // margin-left: 1.5rem;
//   }
//   .header-logo {
//     width: 4rem;
//     margin: .5rem;
//   }
// }

// @media only screen and (max-width: 600px) {
//   header nav {

//     width: 65%;

//   }
// }