.meat-top {
    padding-left: 5%;
    padding-right: 5%;
    text-align: justify;
}
.meat-cards-wrapper {
    width: 80%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 4%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // grid-gap: 2%;
  
}
// .footer {
//     padding-top: 5%;
//     padding-bottom: 2%;
// }
@media only screen and (max-width: 1215px) {
    .meat-cards-wrapper {
        width: 80%;
        padding-left: 5%;
        padding-right: 5%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // grid-gap: 2%;
      
    }
  }
  @media only screen and (max-width: 895px) {
    .meat-cards-wrapper {
        width: 80%;
        padding-left: 5%;
        padding-right: 5%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        // grid-gap: 2%;
        justify-items: center;
        // height: 100%;
      
    }
    // .footer {
    //     padding-top: 15%;
    //     padding-bottom: 2%;
    // }
  }
  // @media only screen and (max-width: 820px) {
  //   .footer {
  //       padding-top: 20%;
  //       padding-bottom: 2%;
  //   }}
  // @media only screen and (max-width: 750px) {
  //   .footer {
  //       padding-top: 15%;
  //       padding-bottom: 2%;
  //   }}
  @media only screen and (max-width: 590px) {
    .meat-cards-wrapper {
        width: 80%;
        padding-left: 5%;
        padding-right: 5%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
    }
    // .footer {
    //     padding-top: 85%;
    //     padding-bottom: 2%;
    // }
  }
  // @media only screen and (max-width: 575px) {
  //   .footer {
  //       padding-top: 95%;
  //       padding-bottom: 2%;
  //   }
  // }
  // @media only screen and (max-width: 516px) {
  //   .footer {
  //       padding-top: 105%;
  //       padding-bottom: 2%;
  //   }
  // }
  // @media only screen and (max-width: 469px) {
  //   .footer {
  //       padding-top: 135%;
  //       padding-bottom: 2%;
  //   }
  // }
  // @media only screen and (max-width: 400px) {
    
  //   .footer {
  //       padding-top: 150%;
  //       padding-bottom: 2%;
  //   }
  // }
  // @media only screen and (max-width: 340px) {
  //   .footer {
  //       padding-top: 155%;
  //       padding-bottom: 2%;
  //   }
  // }
  // @media only screen and (max-width: 320px) {
  //   .footer {
  //       padding-top: 185%;
  //       padding-bottom: 2%;
  //   }
  // }
  // @media only screen and (max-width: 280px) {
  //   .footer {
  //       padding-top: 215%;
  //       padding-bottom: 2%;
  //   }
  // }