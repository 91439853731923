
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: "PT Sans", sans-serif;
      }
    .wool-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 60vh;
        font-size: 3.6rem;
        color: #6c8cb5;
    }
     
    .wool-main-wrapper {
      align-items: center;
      align-content: center;
      align-self: center;
      background-color: #333;
      color: bisque;
      padding: 2%;
    }
    .wool-hero {
      background-color: #637f81; 
      color: bisque;
    }
    .wool-top {
      background-color: #1e5b60;
      text-align: center;
      align-items: center;
      align-content: center;
      color: bisque;
      padding: 0.5%;
      justify-self: center;
      justify-content: center;
      justify-items: center;
    }
    
    .wool-cardWrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1%;
      margin-left: 7%;
      margin-right: 7%;
      margin-top: 2%;
      margin-bottom: 2%;
    
    }
    
    @media (max-width: 5000px) and (min-width:3001px) {
      p {
        font-size: 3rem;
      }
     
      }
  @media (max-width: 3000px) {
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: "PT Sans", sans-serif;
      }
    .wool-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 60vh;
        font-size: 3.6rem;
        color: #6c8cb5;
    }
      
    .wool-main-wrapper {
      align-items: center;
      align-content: center;
      align-self: center;
      background-color: #333;
      color: bisque;
    }
    
    .wool-top {
      background-color: #1e5b60;
      text-align: center;
      align-items: center;
      align-content: center;
      color: bisque;
      padding: 0.5%;
      justify-self: center;
      justify-content: center;
      justify-items: center;
    }
    
    .wool-cardWrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1%;
      margin-left: 7%;
      margin-right: 7%;
      margin-top: 2%;
      margin-bottom: 2%;
    
    }
    }
    
  @media (max-width: 1200px) {
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: "PT Sans", sans-serif;
      }
    .wool-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 60vh;
        font-size: 3.6rem;
        color: #6c8cb5;
    }
      
    .wool-main-wrapper {
      align-items: center;
      align-content: center;
      align-self: center;
      background-color: #333;
      color: bisque;
    }
    
    .wool-top {
      background-color: #1e5b60;
      text-align: center;
      align-items: center;
      align-content: center;
      color: bisque;
      padding: 0.5%;
      justify-self: center;
      justify-content: center;
      justify-items: center;
    }
    
    .wool-cardWrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1%;
      margin-left: 7%;
      margin-right: 7%;
      margin-top: 2%;
      margin-bottom: 2%;
    
    }
    }
  @media (max-width: 800px) {
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: "PT Sans", sans-serif;
      }
    .wool-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 60vh;
        font-size: 3.6rem;
        color: #6c8cb5;
    }
      
    .wool-main-wrapper {
      align-items: center;
      align-content: center;
      align-self: center;
      background-color: #333;
      color: bisque;
    }
    
    .wool-top {
      background-color: #1e5b60;
      text-align: center;
      align-items: center;
      align-content: center;
      color: bisque;
      padding: 0.5%;
      justify-self: center;
      justify-content: center;
      justify-items: center;
    }
    
    .wool-cardWrapper {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1%;
      margin-left: 7%;
      margin-right: 7%;
      margin-top: 2%;
      margin-bottom: 2%;
    
    }
    }
  @media (max-width: 600px) {
  
    .wool-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 60vh;
        font-size: 3.6rem;
        color: #6c8cb5;
    }
      
    .wool-main-wrapper {
      align-items: center;
      align-content: center;
      align-self: center;
      background-color: #333;
      color: bisque;
    }
    
    .wool-top {
      background-color: #1e5b60;
      text-align: center;
      align-items: center;
      align-content: center;
      color: bisque;
      padding: 0.5%;
      justify-self: center;
      justify-content: center;
      justify-items: center;
    }
    
    .wool-cardWrapper {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1%;
      margin-left: 12%;
      margin-right: 7%;
      margin-top: 2%;
      margin-bottom: 20%;
      justify-items: center;
    
    }
    }
    